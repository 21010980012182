import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { AppUtils, Constants } from 'src/helpers';
import { BaseService, GroupService, PageContentService } from 'src/app/shared/services';
import { PageContentDetailModel, SelectListItemModel, SubscriptionSummaryModel } from 'src/models';
import { ListenerService, SubscriptionService, UserService } from 'src/services';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { GroupDetailModel } from 'src/app/shared/models';
import { StorageType } from 'src/helpers/enums';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  hubConnection: HubConnection;
  placement: NzDrawerPlacement = 'left';
  visible = false;
  isCollapsed = false;
  webOne: boolean = false;
  mobileOne: boolean = false;
  screenHeight: any;
  screenWidth: any;
  currentUserId: string;
  currentUsername: string;
  currentUserInitials: string;
  subscriptionId: string;
  subscriptionSummary = new SubscriptionSummaryModel();
  isModelLoaded = false;
  isDropdownVisible = false;
  trialRemainingDays = 0;
  signalRConnectionRetryCounter = 0;
  selectedGroupId = 0;
  groupUserCount = 0;
  subscriptions = new Array<Subscription>();
  ensureSignalRConnectivityInterval: any;
  groups = new Array<GroupDetailModel>();
  subscriptionPlans = new Array<SelectListItemModel>();
  isVisible = false;
  profilePicture: string;
  bookDemo: string;
  disableGroup: boolean = false;
  isOpen = false;
  isDarkMode = false;

  isReseller = false;
  isReferredUser = false;
  logoUrl: string;
  collapsedLogoUrl: string;
  isTrialTimeEnded = false;
  isSubscriptionExpired = false;
  isOnProPlan = false;
  updateAvailable = false;

  intervals = new Array<any>();

  constructor(private appUtils: AppUtils,
    private subscriptionService: SubscriptionService,
    private baseService: BaseService,
    private listenerService: ListenerService,
    private groupService: GroupService,
    private userService: UserService,
    private pageContentService: PageContentService,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.baseService.storageType = this.baseService.getStorageType();
    const isActiveDarkMode = this.baseService.getByKey(Constants.varDarkMode);
    if (isActiveDarkMode === "active") {
      this.renderer.addClass(document.body, 'darkmode');
    }

    this.listenerService.listenApplicationUpdate.subscribe(() => {
      console.log('Update available: Layout');
      this.updateAvailable = true;
    });

    this.isTrialTimeEnded = baseService.isTrialTimeEnded();
    this.getScreenSize();
    this.createSignalRConnection();
    this.loadPageContent();

    const profileUpdatedSubscription = this.listenerService
      .listenProfileUpdated.subscribe(() => {
        this.getUserInfo();
      });

    this.subscriptions.push(profileUpdatedSubscription);

    const subscriptionUpdatedSubscription = this.listenerService
      .listenSubscriptionUpdated.subscribe((data: any) => {
        this.onSubscriptionUpdated(data);
      });

    this.subscriptions.push(subscriptionUpdatedSubscription);

    const groupOwnerChangeServiceSubscription = this.listenerService
      .listenGroupOwnerChange.subscribe((groupId: number) => {
        const group = this.groups.find(x => x.id === groupId);
        if (group) {
          location.reload();
        }
      });

    this.subscriptions.push(groupOwnerChangeServiceSubscription);

    const reloadGroupDataServiceSubscription = this.listenerService
      .listenReloadGroupData.subscribe((data: any) => {
        this.onReloadGroupData(data);
      });

    this.subscriptions.push(reloadGroupDataServiceSubscription);

    const subscriptionChangeSubscription = this.listenerService
      .listenSubscriptionChange.subscribe(() => {
        this.loadLogo();
      });

    this.subscriptions.push(subscriptionChangeSubscription);

    const disableGroupSelectionsubscription = this.listenerService
      .listenDisableGroupSelection.subscribe((disableGroupDropdown: boolean) => {
        this.disableGroupSelection(disableGroupDropdown);
      });

    this.subscriptions.push(disableGroupSelectionsubscription);

    const topNavServiceSubscription = this.listenerService
      .listenTopNav
      .subscribe(() => {
        this.getCurrentUserProfile();
      });

    this.subscriptions.push(topNavServiceSubscription);

    this.selectedGroupId = this.baseService.getGroupId() ?? 0;
  }

  ngOnInit(): void {
    this.startConnection();
    this.ensureSignalRConnectivity();
    this.registerSignalRServerEvents();
    this.getUserInfo();
    this.getCurrentUserProfile();
  }

  ngAfterViewInit(): void {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (this.baseService.storageType === StorageType.local &&
        event.key === Constants.varSelectedGroupId && this.selectedGroupId.toString() !== event.newValue) {
        if (!event.newValue && this.selectedGroupId) {
          this.selectedGroupId = 0;
          this.onSubscriptionPlanChanged();
        } else if (this.subscriptionPlans.find(x => x.keyInt === Number(event.newValue))) {
          this.selectedGroupId = Number(event.newValue);
          this.onSubscriptionPlanChanged();
        }
      }
    });
  }

  toggleSidebar(): void {
    this.isCollapsed = !this.isCollapsed;
    this.listenerService.toggleCollapse(this.isCollapsed);
  }

  public activeDarkMode() {
    this.baseService.setByKey(Constants.varDarkMode, 'active');
    this.renderer.addClass(document.body, 'darkmode');
    this.isDarkMode = true;
    this.listenerService.appThemeChangedListener.next(null);
  }

  public activeLightMode() {
    this.baseService.removeByKey(Constants.varDarkMode);
    this.renderer.removeClass(document.body, 'darkmode');
    this.isDarkMode = false;
    this.listenerService.appThemeChangedListener.next(null);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1200) {
      this.isCollapsed = true;
    }
    if (this.screenWidth > 1200) {
      this.isCollapsed = false;
    }
    if (this.screenWidth <= 768) {
      this.webOne = false;
      this.mobileOne = true;
    }
    if (this.screenWidth > 768) {
      this.mobileOne = false;
      this.webOne = true;
    }
  }

  disableGroupSelection(disableGroupDropdown: boolean): void {
    this.disableGroup = disableGroupDropdown;
  }

  openFeedbackModal(): void {
    this.visible = false;
    this.isVisible = true;
    this.isOpen = false;
  }

  closeSubMenu(): void {
    this.isOpen = false;
  }

  closeModal(): void {
    this.isVisible = false;
  }

  test(): void {

  }

  getCurrentUserProfile() {
    const user = JSON.parse(
      this.appUtils.decrypt(this.baseService.getByKey(Constants.varUserInfo))
    );

    this.currentUserId = user.id;
    this.currentUserInitials = `${user.firstName.charAt(0).toUpperCase()}`;
    this.currentUsername = `${user.firstName}`;
    this.subscriptionId = user.subscriptionId;
    this.groupUserCount = user.groupUserCount;
    if (!AppUtils.isNullOrUndefined(user.image))
      this.profilePicture = this.appUtils.getBlobUrl(environment.azureContainers.userMedia, user?.image);
    else
      this.profilePicture = null;

    this.loadGroups();
    this.loadLogo();
  }

  loadSubscriptionDetailByUserId(userId: string) {
    this.isModelLoaded = false;
    this.isSubscriptionExpired = false;
    this.subscriptionService.getDetailByUserId(userId).subscribe({
      next: (data: any) => {
        this.subscriptionSummary = data;
        this.baseService.setByKey(
          Constants.varSubscriptionSummary,
          JSON.stringify(this.subscriptionSummary)
        );

        this.isOnProPlan = this.subscriptionSummary.planId.toLowerCase().includes('pro');
        this.isModelLoaded = true;

        this.authenticateFreshWidget();
        this.authenticateHubSpotWidget();

        if (this.subscriptionSummary.status === 'Cancelled') {
          this.isSubscriptionExpired = true;
          this.router.navigate(['/subscription/manage']);
        }
      },
      error: (error) => {
        this.isModelLoaded = true;
        this.isSubscriptionExpired = false;
        this.baseService.processErrorResponse(error);
      },
    });
  }

  onVisibleChange(event: any): void {
    this.isDropdownVisible = event;
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  upgradeToProPlan() {
    this.router.navigate(['/subscription/browse-plan'], {
      queryParams: {
        t: 1,
      },
    });
  }

  createSignalRConnection() {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        const token = this.baseService.getAuthToken();
        return !this.appUtils.isNullOrEmpty(token) ? token : null;
      }
    };
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.eventStreamerBaseUrl + 'manager-hub', options)
      .withAutomaticReconnect()
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 10 * 60000;
  }

  startConnection(): void {
    const self = this;
    this.hubConnection
      .start()
      .then(() => {
        console.log('Hub connection started');
        self.signalRConnectionRetryCounter = 0;
        console.log('hub connection state ' + this.hubConnection.state);
      })
      .catch(err => {
        console.log(err);
      });
  }

  ensureSignalRConnectivity(): void {
    const ensureSignalRConnectivityInterval = setInterval(() => {
      if (this.hubConnection.state === 'Disconnected') {
        console.log('Retrying to start the connection. Attempt no: ' + (++this.signalRConnectionRetryCounter));
        this.startConnection();
      }
    }, 5000);

    this.intervals.push(ensureSignalRConnectivityInterval);
  }

  registerSignalRServerEvents(): void {
    this.hubConnection.on('reloadWebAppData', (userIds: Array<string>) => {
      console.log('reloadWebAppData called');
      this.handleReloadWebDataEvent(userIds);
    });
    this.hubConnection.on('refreshSubscriptionStatus', () => {
      console.log('refreshSubscriptionStatus called');
      this.listenerService.topNavListener.next('');
    });
    this.hubConnection.on('reloadGroupData', (data: any) => {
      console.log('reloadGroupData called');
      setTimeout(() => {
        this.listenerService.reloadGroupDataListener.next(data);
      }, 1000);
    });
    this.hubConnection.on('videoEncoded', (data: any) => {
      console.log('videoEncoded called');
      this.handleVideoEncodedEvent(data.CreatedBy, data.OriginalFileName, data.Id);
    });
    this.hubConnection.on('canvaDesignPublished', data => {
      console.log('canvaDesignPublished called');
      this.listenerService.canvaDesignPublishedListener.next(data);
    });
    this.hubConnection.on('reloadBroadcastData', (broadcastIds: Array<number>) => {
      console.log('reloadBroadcastData called');
      this.listenerService.reloadBroadcastDataListener.next(broadcastIds);
    });
    this.hubConnection.on('DisplayStatusChanged', data => {
      console.log('DisplayStatusChanged called');
      this.listenerService.displayStatusChangedListener.next(data);
    });
    this.hubConnection.on('SubscriptionUpdated', data => {
      console.log('SubscriptionUpdated called');
      this.listenerService.subscriptionUpdatedListener.next(data);
    });
  }

  handleReloadWebDataEvent(userIds: Array<string>) {
    // return if list is null or empty
    if (!userIds || userIds.length === 0) {
      return;
    }
    // get current user id
    const currentUserId = this.baseService.getUserId();
    // check if logged in user need to be notified
    if (userIds.indexOf(currentUserId) !== -1) {
      // notify app to reload web data
      this.listenerService.reloadWebDataListener.next('');
    }
  }

  handleVideoEncodedEvent(userId: string, videoName: string, userMediaAppId: number) {
    // return if video is not created by current user
    if (this.baseService.getUserId() !== userId) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    const message = `Your video (${videoName}) was optimized for smooth streaming and will show in selected playlists or broadcasts. It may take a few moments for the video to appear.`;
    const toast = this.baseService.success(message);

    if (Constants.interceptAuthToken) {
      toast.onClick.subscribe(() => this.router.navigate(['/media-app/manage'], { queryParams: { userMediaAppId, video: true } }));
    }

    this.listenerService.reloadUserMediaAppDataListener.next(userMediaAppId);
  }

  loadGroups() {
    const listForUserRequestSubscription = this.groupService.getList()
      .subscribe({
        next: (data: Array<GroupDetailModel>) => {
          if (this.selectedGroupId && this.selectedGroupId !== -1) {
            const group = data.find(x => x.id === Number(this.selectedGroupId));
            if (!group) {
              this.selectedGroupId = 0;
              this.baseService.removeByKey(Constants.varSelectedGroupId);
            }
          }

          Object.assign(this.groups, data);

          this.subscriptionPlans = new Array<SelectListItemModel>();
          let selectListItem: SelectListItemModel;

          if (this.groupUserCount && Number(this.groupUserCount) > 0) {
            // select group if not already saved in local storage
            // so that last saved group can be retained
            const selectedGroupId = this.baseService.getByKey(Constants.varSelectedGroupId);
            if (selectedGroupId == null && this.groups.length > 0) {
              this.selectedGroupId = this.groups[0].id;
            }
          }

          selectListItem = new SelectListItemModel();
          selectListItem.keyInt = -1;
          selectListItem.value = `My Subscription (${this.currentUsername})`;
          this.subscriptionPlans.push(selectListItem);

          this.groups.forEach(group => {
            selectListItem = new SelectListItemModel();
            selectListItem.keyInt = group.id;
            selectListItem.value = `${group.name} (${group.ownerName})`;
            this.subscriptionPlans.push(selectListItem);
          });

          // load subscription detail
          this.loadSubscriptionDetailByGroupId();
        },
        error: (error: any) => {
          this.baseService.processErrorResponse(error);
        }
      });

    this.subscriptions.push(listForUserRequestSubscription);
  }

  loadSubscriptionDetailByGroupId() {
    if (!this.selectedGroupId || this.selectedGroupId === -1) {
      this.loadSubscriptionDetailByUserId(this.currentUserId);
    } else {
      const groupSummary = this.groups.find(x => x.id === Number(this.selectedGroupId));
      this.baseService.setByKey(Constants.varGroupSummary, JSON.stringify(groupSummary));
      this.baseService.setByKey(Constants.varSelectedGroupId, this.selectedGroupId.toString());
      this.loadSubscriptionDetailByUserId(groupSummary.ownerId);
    }

    if (!this.selectedGroupId) {
      this.subscriptionSummary = null;
      this.baseService.removeByKey(Constants.varGroupSummary);
      this.baseService.removeByKey(Constants.varSubscriptionSummary);
    }

    this.baseService.setByKey(Constants.varIsGroupsLoaded, 'Yes');
  }

  onSubscriptionPlanChanged() {
    if (this.selectedGroupId === 0) {
      this.baseService.removeByKey(Constants.varSelectedGroupId);
    } else {
      this.baseService.setByKey(Constants.varSelectedGroupId, this.selectedGroupId.toString());
    }
    this.loadSubscriptionDetailByGroupId();
    this.notifySubscriptionChange();
  }

  notifySubscriptionChange() {
    const selectedGroupId =
      !this.appUtils.isNullOrEmpty(this.selectedGroupId)
        ? Number(this.selectedGroupId)
        : null;
    this.listenerService.subscriptionChangeListener.next(selectedGroupId);
  }

  loadLogo() {
    const user = JSON.parse(this.appUtils.decrypt(this.baseService.getByKey(Constants.varUserInfo)));

    if (!this.appUtils.isNullOrEmpty(user.resellerId)) {
      this.isReseller = true;
    } else if (!this.appUtils.isNullOrEmpty(user.resellerReferrerId)) {
      this.isReferredUser = true;
    }

    if (user.resellerLogos != null && user.resellerLogos.length > 0) {
      const logo = user.resellerLogos.find(x => x.type === Constants.resellerLogoType.lightModeLogo);
      if (logo && logo !== null) {
        this.logoUrl = this.appUtils.getBlobUrl(environment.azureContainers.shared, logo.fileName);
      } else {
        this.logoUrl = null;
      }

      const collapsedLogo = user.resellerLogos.find(x => x.type === Constants.resellerLogoType.lightModeThumbnailLogo);
      if (collapsedLogo && collapsedLogo != null) {
        this.collapsedLogoUrl = this.appUtils.getBlobUrl(environment.azureContainers.shared, collapsedLogo.fileName);
      } else {
        this.collapsedLogoUrl = null;
      }
    }

    const loadLogoInterval = setInterval(() => {
      const isGroupsLoaded = this.baseService.getByKey(Constants.varIsGroupsLoaded);
      if (!this.appUtils.isNullOrEmpty(isGroupsLoaded)) {
        clearInterval(loadLogoInterval);
        if (this.baseService.getGroupId() !== null && this.baseService.getGroupId() !== -1) {
          const groupSummary = JSON.parse(this.baseService.getByKey(Constants.varGroupSummary));
          if (!this.appUtils.isNullOrEmpty(groupSummary)) {
            if (!groupSummary.isGroupOwner && groupSummary.isOwnerReseller) {
              this.isReferredUser = true;
              if (groupSummary.resellerLogos != null && groupSummary.resellerLogos.length > 0) {
                const logo = groupSummary.resellerLogos.find(x => x.type === Constants.resellerLogoType.lightModeLogo);
                if (logo && logo !== null) {
                  this.logoUrl = this.appUtils.getBlobUrl(environment.azureContainers.shared, logo.fileName);
                } else {
                  this.logoUrl = null;
                }

                const collapsedLogo = groupSummary.resellerLogos.find(x => x.type === Constants.resellerLogoType.lightModeThumbnailLogo);
                if (collapsedLogo && collapsedLogo != null) {
                  this.collapsedLogoUrl = this.appUtils.getBlobUrl(environment.azureContainers.shared, collapsedLogo.fileName);
                } else {
                  this.collapsedLogoUrl = null;
                }
              }
            }
          }
        }
      }
    }, 500);
  }

  getUserInfo(): void {
    this.userService.getUserInfo()
      .subscribe({
        next: (response: any) => {
          this.baseService.setUserInformation(response);
          this.currentUserInitials = `${response.firstName.charAt(0).toUpperCase()}`;
          this.currentUsername = `${response.firstName}`;
          if (!AppUtils.isNullOrUndefined(response.image))
            this.profilePicture = this.appUtils.getBlobUrl(environment.azureContainers.userMedia, response?.image);
          else
            this.profilePicture = null;
          if (response.appearanceMode == 2) {
            this.activeDarkMode();
          } else {
            this.activeLightMode();
          }

          this.loadLogo();
        },
        error: (error) => {
          this.baseService.processErrorResponse(error.error);
        }
      });
  }

  loadPageContent() {
    this.pageContentService.getByType("BOOK_A_DEMO_LINK")
      .subscribe({
        next: (data: PageContentDetailModel) => {
          if (data) {
            this.bookDemo = data.content;
          }
        },
        error: error => {
          this.baseService.processErrorResponse(error);
        }
      });
  }

  isActiveRoute(route: string, matchExact = false): boolean {
    if (matchExact) {
      return this.router.url === route;
    }
    return this.router.url.includes(route);
  }

  onSubscriptionUpdated(data: any) {
    if ((!data.GroupId && data.UserIds && data.UserIds.indexOf(this.baseService.getUserId()))
      || (data.GroupId && data.GroupId == this.selectedGroupId && data.UserIds
        && data.UserIds.indexOf(this.baseService.getUserId()))
    ) {
      this.getCurrentUserProfile();
    } else if (data.GroupId && data.GroupOwnerId && data.GroupOwnerId == this.baseService.getUserId()) {
      if (!this.selectedGroupId || this.selectedGroupId === -1) {
        this.getCurrentUserProfile();
      } else {
        var groupSummary = JSON.parse(this.baseService.getByKey(Constants.varGroupSummary));
        if (groupSummary && groupSummary.isGroupOwner) {
          this.getCurrentUserProfile();
        }
      }
    }
  }

  onReloadGroupData(data: any) {
    if (this.selectedGroupId.toString() === data.GroupId.toString()) {
      this.groupService.getList()
        .subscribe({
          next: (response: Array<GroupDetailModel>) => {
            const group = response.filter(x => x.id.toString() === data.GroupId.toString());
            if (this.appUtils.isNullOrEmpty(group) || group.length === 0) {
              this.baseService.setByKey(Constants.varSelectedGroupId, '');
            }
            location.reload();
          },
          error: (error: any) => {
            this.baseService.processErrorResponse(error);
          }
        });
    } else {
      location.reload();
    }
  }

  authenticateFreshWidget() {
    this.userService.getFreshWidgetToken().subscribe({
      next: (res: string) => {
        this.baseService.authenticateFreshWidget(res);
      }
    });
  }

  authenticateHubSpotWidget() {
    this.userService.getHubSpotVisitorIdentificationToken().subscribe({
      next: (res: string) => {
        this.baseService.authenticateHubSpotWidget(res);
      }
    });
  }

  refreshApplication() {
    location.reload();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => { s.unsubscribe(); });

    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        console.log('Hub connection stopped');
      })
        .catch(err => {
          console.log('Error while stopping the connection- ' + err);
        });
    }

    this.intervals.forEach(interval => {
      clearInterval(interval);
    });
  }
}
